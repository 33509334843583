var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-disposal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "title": "Pilih Asset"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.selectedPenyimpanans.length < 1
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(" Pilih ")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.penyimpanans
    },
    scopedSlots: _vm._u([{
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.stok > 0 ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedPenyimpanans,
            callback: function ($$v) {
              _vm.selectedPenyimpanans = $$v;
            },
            expression: "selectedPenyimpanans"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "SlashIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.asset ? item.asset.nama : '-') + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '') + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }